
          @import "@/styles/global/utils.scss";
          @import "@/styles/cjp/utils.scss";
        













.callback-loading {
  max-width: 1200px;
  margin: 11rem auto 0 auto;
}
