@keyframes shake-right {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(5px);
  }
}

@keyframes shake-left {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes spin {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
